import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';  
import { Observable } from 'rxjs';

@Injectable()
export class DataService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

  }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.baseUrl + url);
  }

  create<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }

  update<T>(url: string, id: string, body: any): Observable<T> {


    if ("undefined" !== typeof (body["modifiedBy"])) {
      body.modifiedBy = localStorage.getItem('userId');
    }

    return this.http.put<T>(this.baseUrl + url + '/' + id, body);
  }

  delete<T>(url: string, id: string): Observable<T> {
    const userId = localStorage.getItem('userId');
    return this.http.delete<T>(this.baseUrl + url + '/' + id + '/' + userId);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, body);
  }

  upload(apiName: string, resource: FormData) {
    return this.http.post(apiName, resource);
  }
}
