import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'jobs',
        loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
      },
      {
        path: 'article',
        loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule),
      },
      {
        path: 'how-we-hire',
        loadChildren: () => import('./modules/how-we-hire/how-we-hire.module').then(m => m.ArticleModule),
      },
      {
        path: 'testimonial',
        loadChildren: () => import('./modules/testimonial/testimonial.module').then(m => m.TestimonialModule),
      },
      {
        path: 'job-detail',
        loadChildren: () => import('./modules/job-detail/job-detail.module').then(m => m.JobDetailModule),
      }
    ]
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
