<!-- header -->
<header class="site-header mo-left header fullwidth">
    <!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix">
            <div class="container-fluid clearfix">
                <!-- website logo -->
                <div class="logo-header mostion">
                    <a href="https://www.korektel.com/en" target="_blank"><img
                            src="https://www.korektel.com/assets/icons/korek-logo.svg" class="logo" alt=""></a>
                </div>
                <!-- nav toggle button -->
                <!-- nav toggle button -->
                <button class="navbar-toggler collapsed navicon justify-content-end" type="button"
                    data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>


                <!-- extra nav -->
                <!-- <div class="extra-nav">
                    <div class="extra-cell">
                        <a asp-area="Identity" asp-page="/Account/Register">Sign Up</a>
                        <a href="javascript:;" data-toggle="modal" data-target="#car-details">Login</a>
                    </div>
                </div> -->
                <!-- main nav -->


                <div class="header-nav navbar-collapse collapse justify-content-start" id="navbarNavDropdown">
                    <ul class="nav navbar-nav">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li><a routerLink="/jobs/">Career Opportunities</a></li>
                        <li>
                            <a routerLink="/how-we-hire" asp-route-id="28">How we Hire</a>
                        </li>
                        <li>
                            <a href="javascript:;">Students <i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a class="dez-page" [routerLink]="['/article']"
                                        [queryParams]="{id:'25'}">Internship</a></li>
                                <li><a target="_blank" href="https://www.korektel.com/en/pages/korek-ambassador"
                                        class="dez-page">Korek Ambassador</a></li>
                                <li>
                                    <a [routerLink]="['/testimonial']" [queryParams]="{type:'internship'}"
                                        class="dez-page">
                                        Students Testimonials
                                    </a>
                                </li>
                                <li><a [routerLink]="['/article']" [queryParams]="{id:'29'}" class="dez-page">Offers</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;">Korek's Life <i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a [routerLink]="['/article']" [queryParams]="{id:'27'}" class="dez-page">About
                                        Us</a></li>
                                <li><a [routerLink]="['/article']" [queryParams]="{id:'28'}" class="dez-page">Events</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/testimonial']" [queryParams]="{type:'employee'}"
                                        class="dez-page">
                                        Testimonials
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;">Help & Resources <i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a [routerLink]="['/article']" [queryParams]="{id:'18'}" class="dez-page">Career
                                        Advice</a>
                                </li>
                                <li><a [routerLink]="['/article']" [queryParams]="{id:'19'}" class="dez-page">Job
                                        Interview Tips
                                    </a></li>
                                <li> <a [routerLink]="['/article']" [queryParams]="{id:'17'}" class="dez-page">Why Work
                                        for Korek
                                    </a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- main header END -->
</header>
<!-- header END -->


<!-- Modal Box -->
<div class="modal fade" id="car-details" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body m-a0 clearfix">
                <div class="lead-form browse-job text-left">
                    <form (ngSubmit)="login()">
                        <h3 class="m-t0">Login</h3>
                        <div class="form-group">
                            <input class="form-control" type="text" name="username"
                                [(ngModel)]="authenticateRequest.username" placeholder="Email" />
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="password" name="password"
                                [(ngModel)]="authenticateRequest.password" placeholder="Password" />
                        </div>
                        <div class="clearfix">
                            <button type="submit" class="btn-primary site-button btn-block">Login </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Box End -->