import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticateRequest } from '@core/models';
import { UserService } from '@core/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  authenticateRequest: AuthenticateRequest = new AuthenticateRequest();

  constructor(private userService: UserService) {

  }

  ngOnInit(): void { }

  async login() {
    var login = await this.userService.login(this.authenticateRequest).toPromise();

  }

}
