import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticateRequest } from '../models';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private dataService: DataService) { }

  api: string = "api/Users";

  login(authenticateRequest: AuthenticateRequest) : Observable<any> {
    return this.dataService.post(`${this.api}/Login`, authenticateRequest);
  }

  register(authenticateRequest: AuthenticateRequest) {
    return this.dataService.post(`${this.api}/Register`, authenticateRequest);
  }
}
