
import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';

import { Injectable, Injector, Inject } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpSentEvent,
} from '@angular/common/http';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(@Inject('BASE_URL') private baseUrl: string) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const token: string = localStorage.getItem('token');

    req = req.clone({ headers: req.headers.set('Accept', 'application/json'), url: req.url });

    return next.handle(req);
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  handle400Error(error) {

    // if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
    //     // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
    //     this.authService.logout();
    // }

    return observableThrowError(error);
  }
}
