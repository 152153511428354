<div class="page-wraper" id="app">

    <app-header></app-header>

    <!-- Content -->
    <div class="page-content" style="min-height: calc( 100vh - 144px );">
        <router-outlet></router-outlet>
    </div>
    <!-- Content End -->
    <!-- Modal Box -->
    <div class="modal fade lead-form-modal" id="car-details" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                <div class="modal-body row m-a0 clearfix">
                    <div class="col-lg-6 col-md-6 overlay-primary-dark d-flex p-a0" style="background-image: url(Assets/new/images/background/bg3.jpg);  background-position:center; background-size:cover;">
                        <div class="form-info text-white align-self-center">
                            <h3 class="m-b15">Login To You Now</h3>
                            <p class="m-b15">Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry.
                            </p>
                            <ul class="list-inline m-a0">
                                <li><a href="#" class="m-r10 text-white"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="#" class="m-r10 text-white"><i class="fa fa-google-plus"></i></a></li>
                                <li><a href="#" class="m-r10 text-white"><i class="fa fa-linkedin"></i></a></li>
                                <li><a href="#" class="m-r10 text-white"><i class="fa fa-instagram"></i></a></li>
                                <li><a href="#" class="m-r10 text-white"><i class="fa fa-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-a0">
                        <div class="lead-form browse-job text-left">
                            <form>
                                <h3 class="m-t0">Personal Details</h3>
                                <div class="form-group">
                                    <input value="" class="form-control" placeholder="Name" />
                                </div>
                                <div class="form-group">
                                    <input value="" class="form-control" placeholder="Mobile Number" />
                                </div>
                                <div class="clearfix">
                                    <button type="button" class="btn-primary site-button btn-block">Submit </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Box End -->

    <app-footer></app-footer>
    <!-- scroll top button -->
    <button class="scroltop fa fa-arrow-up"></button>
</div>