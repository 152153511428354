<!-- Footer -->
<footer class="site-footer">
    <div class="footer-bottom">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <span>
                        © IConnect {{ year }}. All Rights Reserved.
                    </span>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer END -->